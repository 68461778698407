/*eslint-disable*/
import axios from "axios"
const userRoute = "https://server.earthbound.co.in/api/v1/users/"
const mainRoute = "https://server.earthbound.co.in/api/v1/"
const accessKey = "cdez1DbitR/s4z57oCS2eWgrxCjg9nA4gX2qbmnc"

const checkAuthStatus = async(token) => {
    const route = mainRoute + 'checkAuthStatus'
    return await axios.get(route, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        accessKey
      }
    }).then((data) => {
      return data.data
    }).catch((e) => {
      return {
        status: false,
        msg: e
      }
    })
  }

const getUser = async (id, token) => {
    const userRouteUrl = userRoute + id
    return await axios.get(userRouteUrl,{
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          accessKey
        }
    })
    .then((data) => {
        return data.data
    })
    .catch((err) => {
        return {
            status: false,
            msg: err
        }
    })
}

const registerUser = async (payload, id, token) => {
    const registerRouteUrl = userRoute + 'register/' + id
    return await axios.post(registerRouteUrl, {
        ...payload
    },{
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          accessKey
        }
      })
    .then((data) => {
        return data.data
    })
    .catch((err) => {
        return {
            status: false,
            msg: err
        }
    })
}

const editUser = async (payload, id, token) => {
    const editRouteUrl = userRoute + id
    const formData = new FormData();
    formData.append('name', payload.name)
    formData.append('email', payload.email)
    formData.append('mobile', payload.mobile)
    formData.append('password', payload.password)
    formData.append('profilePic', payload.profilePic)

    let bytes = 0;
      for (let item of formData) {
          if (typeof item[1] === 'string') {
              bytes += new Blob([item[1]]).size;
          } else {
              bytes += item[1].size;
          }
      }

    let sizeInMB = bytes/1048576

    if (sizeInMB<2.99){
        return await axios.put(editRouteUrl, formData,
        {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: {
              accessKey
            }
          })
        .then((data) => {
            return data.data
        })
        .catch((err) => {
            return {
                status: false,
                msg: err
            }
        })
    }
    else{
        return {
            status: false,
            msg: "Make sure Image size (overall) doesn't cross 2.98MB limit!"
        }
    }
}

const kycApi = async (payload, id, token) => {
    const kycUrl = userRoute + 'kyc/' + id
    const formData = new FormData();
    formData.append('aadharNo', payload.aadharNo)
    formData.append('aadharFrontPic', payload.aadharFrontPic)
    formData.append('aadharBackPic', payload.aadharBackPic)

    let bytes = 0;
      for (let item of formData) {
          if (typeof item[1] === 'string') {
              bytes += new Blob([item[1]]).size;
          } else {
              bytes += item[1].size;
          }
      }

    let sizeInMB = bytes/1048576
    if (sizeInMB < 2.99){
        return await axios.put(kycUrl,formData,
            {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data"
                },
                params: {
                  accessKey
                }
            })
        .then((data) => {
            return data.data
        })
        .catch((err) => {
            return {
                status: false,
                msg: err
            }
        })
    }
    else{
        return {
            status: false,
            msg: "Make sure Image size (overall) doesn't cross 2.98MB limit!"
        }
    }
}

const panApi = async (payload, id, token) => {
    const url = userRoute + 'pan/' + id
    const formData = new FormData();
    formData.append('panNo', payload.panNo)
    formData.append('panPic', payload.frontPanPic)
    formData.append('name', payload.panName)

    let bytes = 0;
      for (let item of formData) {
          if (typeof item[1] === 'string') {
              bytes += new Blob([item[1]]).size;
          } else {
              bytes += item[1].size;
          }
      }

    let sizeInMB = bytes/1048576
    if (sizeInMB < 2.99){
        return await axios.put(url, formData,
            {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data"
                },
                params: {
                  accessKey
                }
            })
        .then((data) => {
            return data.data
        })
        .catch((err) => {
            return {
                status: false,
                msg: err
            }
        })
    }
    else{
        return {
            status: false,
            msg: "Make sure Image size (overall) doesn't cross 2.98MB limit!"
        }
    }
}

const bankDetailsApi = async (payload, id, token) => {
    const url = userRoute + 'bankDetails/' + id
    return await axios.put(url, {
        ...payload
    },{
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          accessKey
        }
    })
    .then((data) => {
        return data.data
    })
    .catch((err) => {
        return {
            status: false,
            msg: err
        }
    })
}

const bankDocApi = async (payload, id, token) => {
    const url = userRoute + 'bankDocs/' + id
    const formData = new FormData();
    formData.append('bankDocPic', payload.bankDocPic)

    let bytes = 0;
      for (let item of formData) {
          if (typeof item[1] === 'string') {
              bytes += new Blob([item[1]]).size;
          } else {
              bytes += item[1].size;
          }
      }

    let sizeInMB = bytes/1048576
    if (sizeInMB < 2.99){
        return await axios.put(url,formData,
            {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data"
                },
                params: {
                  accessKey
                }
            })
        .then((data) => {
            return data.data
        })
        .catch((err) => {
            return {
                status: false,
                msg: err
            }
        })
    }
    else{
        return {
            status: false,
            msg: "Make sure Image size (overall) doesn't cross 2.98MB limit!"
        }
    }
}

const addressApi = async (payload, id, token) => {
    const url = userRoute + 'address/' + id
    return await axios.put(url, {
        ...payload
    },{
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          accessKey
        }
    })
    .then((data) => {
        return data.data
    })
    .catch((err) => {
        return {
            status: false,
            msg: err
        }
    })
}

const contactApi = async (payload, id, token) => {
    const url = userRoute + 'contact/' + id
    return await axios.put(url, {
        ...payload
    },{
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          accessKey
        }
    })
    .then((data) => {
        return data.data
    })
    .catch((err) => {
        return {
            status: false,
            msg: err
        }
    })
}

const nomineeApi = async (payload, id, token) => {
    const url = userRoute + 'nominee/' + id
    return await axios.put(url, {
        ...payload
    },{
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          accessKey
        }
    })
    .then((data) => {
        return data.data
    })
    .catch((err) => {
        return {
            status: false,
            msg: err
        }
    })
}


export {getUser, registerUser, editUser, kycApi, panApi, 
    bankDetailsApi, bankDocApi, addressApi, contactApi, nomineeApi, checkAuthStatus}