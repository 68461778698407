/*eslint-disable*/
import { adminActions, authActions, businessActions, errorActions, figureActions, notifyActions, uiActions } from "../reducer"
import { addressApi, bankDetailsApi, bankDocApi, checkAuthStatus, contactApi, editUser, getUser, kycApi, nomineeApi, panApi, registerUser } from "../API/userApi";
import { getDownline, getMlmInfo } from "../API/mlmApi";

export const setAuthData = (token,setAuthLoading) => {
    return async (dispatch) => {
        setAuthLoading(true)
        const response = await checkAuthStatus(token)
        if (response.status === false){
            dispatch(logout(setAuthLoading))
        }else{
            dispatch(authActions.setAuth(response.data));
            dispatch(setProfile(response.data.id,response.data.token))
            dispatch(setMlmInfo(response.data.id,response.data.token))
        }
        setAuthLoading(false)
    }
}

export const setProfile = (id, token) => {
    return async (dispatch) => {
        const response = await getUser(id,token)
        if (response.status === false){
            dispatch(errorActions.setError({
                status: true,
                message: response.msg
            }))
        }
        else{
            dispatch(authActions.setProfile(response.data))
        }
    }
}

export const setMlmInfo = (id, token) => {
    return async (dispatch) => {
        let response = await getMlmInfo(id,token)
        if (response.status === false){
            dispatch(errorActions.setError({
                status: true,
                message: response.msg
            }))
        }
        else{
            dispatch(authActions.setMlmInfo(response.data))
            dispatch(businessActions.setDownline(response.data))
        }

        response = await getDownline(id,token) 
        if (response.status === false){
            dispatch(errorActions.setError({
                status: true,
                message: response.msg
            }))
        }
        else{
            dispatch(businessActions.setDownlines(response.data))
        }
    }
}

export const registerProfile =  (payload, id, token) => {
    return async (dispatch) => {
        const response  = await registerUser(payload, id, token)
        if (response.status === false){
            dispatch(errorActions.setError({
                status: true,
                message: response.msg
            }))
        }
        else{
            dispatch(notifyActions.setNotification({
                status: true,
                message: "Successfully Registered"
            }))
            // dispatch(businessActions.addDownline(payload))
        }
    }
}

export const editProfile =  (payload, id, token) => {
    return async (dispatch) => {
        const response  = await editUser(payload, id, token)
        if (response.status === false){
            dispatch(errorActions.setError({
                status: true,
                message: response.msg
            }))
        }
        else{
            dispatch(notifyActions.setNotification({
                status: true,
                message: "Successfully Updated"
            }))
            dispatch(setProfile(id,token))
        }
    }
}

export const addKyc = (payload,id, token) => {
    return async (dispatch) => {
        const response  = await kycApi(payload, id, token)
        if (response.status === false){
            dispatch(errorActions.setError({
                status: true,
                message: response.msg
            }))
        }
        else{
            dispatch(notifyActions.setNotification({
                status: true,
                message: "Successfully Aadhar Updated"
            }))
            dispatch(setProfile(id,token))
        }
    }
}

export const addPan = (payload,id, token) => {
    return async (dispatch) => {
        const response  = await panApi(payload, id, token)
        if (response.status === false){
            dispatch(errorActions.setError({
                status: true,
                message: response.msg
            }))
        }
        else{
            dispatch(notifyActions.setNotification({
                status: true,
                message: "Successfully Pan Updated"
            }))
            dispatch(setProfile(id,token))
        }
    }
}

export const addBankDetails = (payload,id, token) => {
    return async (dispatch) => {
        const response  = await bankDetailsApi(payload, id, token)
        if (response.status === false){
            dispatch(errorActions.setError({
                status: true,
                message: response.msg
            }))
        }
        else{
            dispatch(notifyActions.setNotification({
                status: true,
                message: "Successfully Bank Details has been Updated"
            }))
            dispatch(setProfile(id,token))
        }
    }
}

export const addBankDoc = (payload,id,token) => {
    return async (dispatch) => {
        const response  = await bankDocApi(payload, id, token)
        if (response.status === false){
            dispatch(errorActions.setError({
                status: true,
                message: response.msg
            }))
        }
        else{
            dispatch(notifyActions.setNotification({
                status: true,
                message: "Successfully Bank Documents has been Updated"
            }))
            dispatch(setProfile(id,token))
        }
    }
}

export const addAdress = (payload,id,token) => {
    return async (dispatch) => {
        const response  = await addressApi(payload, id, token)
        if (response.status === false){
            dispatch(errorActions.setError({
                status: true,
                message: response.msg
            }))
        }
        else{
            dispatch(notifyActions.setNotification({
                status: true,
                message: "Successfully Address has been Updated"
            }))
            dispatch(setProfile(id,token))
        }
    }
}

export const addContact = (payload,id, token) => {
    return async (dispatch) => {
        const response  = await contactApi(payload, id, token)
        if (response.status === false){
            dispatch(errorActions.setError({
                status: true,
                message: response.msg
            }))
        }
        else{
            dispatch(notifyActions.setNotification({
                status: true,
                message: "Successfully Contact Details has been Updated"
            }))
            dispatch(setProfile(id,token))
        }
    }
}

export const addNominee = (payload,id, token) => {
    return async (dispatch) => {
        const response  = await nomineeApi(payload, id, token)
        if (response.status === false){
            dispatch(errorActions.setError({
                status: true,
                message: response.msg
            }))
        }
        else{
            dispatch(notifyActions.setNotification({
                status: true,
                message: "Successfully Nominee Details has been Updated"
            }))
            dispatch(setProfile(id,token))
        }
    }
}

export const logout = (setAuthLoading) => {
    return async (dispatch) => {
        setAuthLoading(true)
        dispatch(authActions.clearAuth())
        dispatch(adminActions.clearAdmin())
        dispatch(errorActions.resetError())
        dispatch(figureActions.clearFigures())
        dispatch(businessActions.clearBusiness())
        dispatch(notifyActions.resestNotification())
        setAuthLoading(false)
    }
}