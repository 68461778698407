/*eslint-disable*/
import { configureStore, combineReducers, getDefaultMiddleware,
} from "@reduxjs/toolkit"
import { uiSlice, authSlice, businessSlice, errorSlice, notifySlice, figureSlice, adminSlice } from "./reducer"
import storage from 'redux-persist/lib/storage';
import { persistReducer,persistStore,FLUSH,REHYDRATE,PAUSE,PERSIST,PURGE,REGISTER, } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, combineReducers({
  ui: uiSlice.reducer,
  auth: authSlice.reducer,
  business: businessSlice.reducer,
  error: errorSlice.reducer,
  notify: notifySlice.reducer,
  figure: figureSlice.reducer,
  admin: adminSlice.reducer
}));

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})

export const persistor = persistStore(store)

export default store
