/*eslint-disable*/
import React, { Component, Suspense, useContext, useEffect, useState } from 'react'
import { HashRouter, Route, Routes, useNavigate } from 'react-router-dom'
import './scss/style.scss'
import './css/style.css'
import { useDispatch, useSelector } from 'react-redux'
import { logout, setAuthData } from './store/Actions/userActions'
import queryString from 'query-string';
import { UserContext } from './context/users'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state) =>  state.auth.data)
  const {authLoading,setAuthLoading} = useContext(UserContext)
  useEffect(() => {
    if (!auth){
      const queryParams = queryString.parse(window.location.search)
      let queryObject = JSON.parse(JSON.stringify(queryParams))
      if (queryObject.hasOwnProperty('token')){
        dispatch(setAuthData(queryObject['token'], setAuthLoading))
      }
      else{
        console.log(auth, queryObject.hasOwnProperty('token'))
        window.open("https://earthbound.co.in/", "_self");
      }
    }else{
      dispatch(setAuthData(auth.token, setAuthLoading))
    }
  },[])

  useEffect(() => {
    if (authLoading === false && !auth){
      console.log(auth, authLoading)
      setTimeout(function() {
        window.open("https://earthbound.co.in/", "_self");
      }, 1000);
    }
  },[authLoading,auth])
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
