/*eslint-disable*/
import axios from "axios"
const userRoute = "https://server.earthbound.co.in/api/v1/mlm/"
const accessKey = "cdez1DbitR/s4z57oCS2eWgrxCjg9nA4gX2qbmnc"

const getIncomeHistory = async (id, token) => {
    const url = userRoute + 'incomeHistory/' + id
    return await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          accessKey
        }
      })
    .then((data) => {
        return data.data
    })
    .catch((err) => {
        return {
            status: false,
            msg: err
        }
    })
}

const getBusinessFig = async (id, token) => {
    const url = userRoute + 'businessFig/' + id
    return await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          accessKey
        }
      })
    .then((data) => {
        return data.data
    })
    .catch((err) => {
        return {
            status: false,
            msg: err
        }
    })
}

const getMlmInfo = async (id, token) => {
  const url = userRoute + 'userAndMlm/'+ id
  return await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        accessKey
      }
    })
  .then((data) => {
      return data.data
  })
  .catch((err) => {
      return {
          status: false,
          msg: err
      }
  })
}

const getDownline = async (id, token) => {
  const url = userRoute + 'downline/'+ id
  return await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        accessKey
      }
    })
  .then((data) => {
      return data.data
  })
  .catch((err) => {
      return {
          status: false,
          msg: err
      }
  })
}

const getDownlineSales = async (id, token) => {
  const url = userRoute + 'downlineSales/'+ id
  return await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        accessKey
      }
    })
  .then((data) => {
      return data.data
  })
  .catch((err) => {
      return {
          status: false,
          msg: err
      }
  })
}

const getMlmInfoFromList = async (idList, token) => {
  const url = userRoute + 'userAndMlm/'
  return await axios.post(url, {
      idList
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        accessKey
      }
    })
  .then((data) => {
      return data.data
  })
  .catch((err) => {
      return {
          status: false,
          msg: err
      }
  })
}

const getTopBuyers = async (token) => {
    const url = userRoute + '/get/TopBuyers'
    return await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          accessKey
        }
      })
    .then((data) => {
        return data.data
    })
    .catch((err) => {
        return {
            status: false,
            msg: err
        }
    })
}

const registerDepositRequest = async (id, amount, token) => {
  const registerRouteUrl = userRoute + '/rechargeRequest/' + id
  return await axios.post(registerRouteUrl, {
      amt: amount
  },{
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        accessKey
      }
    })
  .then((data) => {
      return data.data
  })
  .catch((err) => {
      return {
          status: false,
          msg: err
      }
  })
}

const registerWithdrawRequest = async (id, amount, token) => {
  const registerRouteUrl = userRoute + '/withdrawRequest/' + id
  return await axios.post(registerRouteUrl, {
      amt: amount
  },{
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        accessKey
      }
    })
  .then((data) => {
      return data.data
  })
  .catch((err) => {
      return {
          status: false,
          msg: err
      }
  })
}


export {getIncomeHistory, getBusinessFig, getMlmInfo, getMlmInfoFromList, 
  getDownline, getDownlineSales, getTopBuyers, registerDepositRequest, registerWithdrawRequest}