/*eslint-disable*/
import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        sidebarShow: true,
    },
    reducers: {
        toggle(state) {
            state.sidebarShow = !state.sidebarShow
        },
        setSideBar(state, action){
            state.sidebarShow = action.payload
        }
    }
})

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        data: null,
        profile: null,
        mlmInfo: null
    },
    reducers: {
        setAuth(state, action) {
            state.data = {...state.data, ...action.payload}
        },
        setProfile(state, action) {
            state.profile = {...state.profile , ...action.payload}
        },
        editProfile(state, action){
            
        },
        setMlmInfo(state, action){
            state.mlmInfo = {...state.mlmInfo, ...action.payload}
        },
        clearAuth(state){
            state.profile = null
            state.data = null
            state.mlmInfo = null
        }
    }
})

const notifySlice = createSlice({
    name: 'notification',
    initialState: {
        show: false,
        message: null
    },
    reducers: {
        setNotification(state, action) {
            state.show = action.payload.show
            state.message = action.payload.message
        },
        resestNotification(state) {
            state.show = false
            state.message = null
        }
    }
})

const ticketSlice = createSlice({
    name: 'ticket',
    initialState: {
        tickets: []
    },
    reducers: {
        setTickets(state, action) {
            state.tickets = action.payload
        },
        addTickets(state, action){
            state.tickets.push(action.payload)
        },
        clearTickets(state) {
            state.tickets = []
        }
    }
})

const businessSlice = createSlice({
    name: 'business',
    initialState: {
        downline: {},
        downlines: [],
        downlineSales: [],
        incomeHistory: [],
        topBuyers: []
    },
    reducers: {
        setDownlineSales(state, action){
            state.downlineSales = action.payload
        },
        addDownline(state, action) {
            state.downline.push(action.payload)
        },
        setDownline(state, action){
            state.downline = action.payload
        },
        setDownlines(state, action){
            state.downlines = action.payload
        },
        setIncomeHistory(state, action){
            state.incomeHistory = action.payload
        },
        setTopBuyers(state, action){
            state.topBuyers = action.payload
        },
        clearBusiness(state) {
            state.downline = {}
            state.downlines = []
            state.incomeHistory = []
            state.downlineSales = [],
            state.topBuyers = []
        }
    }
})

const figureSlice = createSlice({
    name: 'figure',
    initialState: {
        comission: null,
        personalBusiness: null,
        totalSales: null,
        wallet: null,
        totalWithDraw: null,
        usedAmount: null
    },
    reducers:{
        setWallet(state, action){
            state.wallet = action.payload
        },
        setTotalWithDraw(state, action){
            state.totalWithDraw = action.payload
        },
        setUsedAmount(state, action){
            state.usedAmount = action.payload
        },
        clearFigures(state){
            state.comission = null
            state.personalBusiness = null
            state.totalSales = null
            state.wallet = null
            state.totalWithDraw = null
            state.usedAmount = null
        }
    }
})

const errorSlice = createSlice({
    name: 'error',
    initialState: {
        message: null,
        status: false
    },
    reducers:{
        setError(state, action){
            state.message = action.payload.message
            state.status = action.payload.status
        },
        resetError(state){
            state.message = null
            state.status = false
        }
    }
})

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        users: [],
        walletHistory: [],
        withDrawRequest: [],
        rechargeRequest: [],
        figures: {
            userCount: 0,
            adminCount: 0,
            activeUsers: 0,
            blockedUsers: 0,
            totalCompanyWallet: 0,
            totalWithDraw: 0,
            totalRecharge: 0
        }
    },
    reducers:{
        setUser(state, action){
            state.users = action.payload
        },
        setWalletHistory(state, action){
            state.walletHistory = action.payload
        },
        setWalletRequest(state, action){
            state.withDrawRequest = action.payload.withdraw
            state.rechargeRequest = action.payload.recharge
        },
        setFigures(state,action){
            state.figures = action.payload
        },
        clearAdmin(state){
            state.users = [],
            state.walletHistory = [],
            state.rechargeRequest = [],
            state.withDrawRequest = [],
            state.figures = {
                userCount: 0,
                adminCount: 0,
                activeUsers: 0,
                blockedUsers: 0,
                totalCompanyWallet: 0,
                totalWithDraw: 0,
                totalRecharge: 0
            }
        }
    }
})

const uiActions = uiSlice.actions
const authActions = authSlice.actions
const errorActions = errorSlice.actions
const businessActions = businessSlice.actions
const notifyActions = notifySlice.actions
const figureActions = figureSlice.actions
const adminActions = adminSlice.actions

export {uiSlice, authSlice, errorSlice, businessSlice, notifySlice, figureSlice, adminSlice}
export {uiActions, authActions, errorActions, businessActions, notifyActions, figureActions, adminActions}